import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import RawHTML from '../components/rawHTML'
import Discussion from './DiscussionThread';

class Cipher extends React.Component {
    constructor() {
		super();

		this.state = {
			helpShown: false,
			solutionShown: false,
		}

		this.showHelp = this.showHelp.bind(this);
		this.showSolution = this.showSolution.bind(this);
	}

	showHelp(e) {
		e.preventDefault()
		this.setState({
			helpShown: true
		})
	}

	showSolution(e) {
		e.preventDefault()
		this.setState({
			solutionShown: true
		})
	}

	render() {
		const cipher = this.props.pageContext.cipher
		const cipherPdfUrl = cipher.cipher_url.replace( 'png', 'pdf' )
		const cipherMenu = [ 'start', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'cíl', 'a', 'a1', 'a2', 'a3', 'a4', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r' ]

		return (
			<Layout>
			<SEO
			title={`Šifra ${cipher.id}${cipher.name ? ': ' + cipher.name : ''}`}
			description={`Šifra ${cipher.id} ze hry Navíc, která se konala v Praze 13. června 2020.`}
			/>
				<div id="main">
					<section id="one">
						<div className="inner">
							<div className="quick-cipher-nav">
								<Link to="sifry">Všechny šifry</Link>
								{cipherMenu.map(i => { return <Link className={cipher.id === i ? 'active' : ''} key={`nav-${i}`} to={`sifry/${i}`}>{i}</Link> }) }
							</div>
							<header className="major">
								<h2>{`Šifra ${cipher.id}${cipher.name ? ': ' + cipher.name : ''}`}</h2>
							</header>
							<img src={`https://2020.navic.sifrovacky.cz/webinfo/cipher-images/${cipher.cipher_url}`} width="100%" alt="Šifra" />
							<a href={`https://2020.navic.sifrovacky.cz/webinfo/cipher-images/${cipherPdfUrl}`} target="_blank" rel="noopener noreferrer">Zadání v PDF</a> (+ <a href={`https://2020.navic.sifrovacky.cz/webinfo/cipher-images/${cipherPdfUrl.replace('.pdf', '-poster.pdf')}`} target="_blank" rel="noopener noreferrer">poster</a>)
							{this.state.helpShown || ! cipher.help ? null : <> - <a href="" onClick={this.showHelp}>Nápověda</a></>}
							{this.state.solutionShown || ! cipher.solution ? null : <> - <a href="" onClick={this.showSolution}>Řešení</a></>}

							<div id="help-box" className={this.state.helpShown ? '' : 'hidden'}>
								<strong>Nápověda:</strong> {cipher.help}
							</div>

							<div id="solution-box" className={this.state.solutionShown ? '' : 'hidden'}>
								<RawHTML>{cipher.solution}</RawHTML>
							</div>

							<header className="cipher_dicussion major">
								<h2>Diskuse k šifře</h2>
							</header>
							<Discussion thread={cipher.id} />
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}
export default Cipher
