import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Reportáze &amp; Fotky"
        description="Reportáže a fotogalerie ze hry Navíc, která se konala v Praze 13. června 2020."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Reportáže &amp; Fotky</h2>
                    </header>
					<ul>
						<li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1zVyOyr1mUJqpfs9AU7cxIZctLMyUXYMxikwnhqaGVKY/edit#">Reportáž týmu Krajní prostředky</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1CTopHU2De0zgbShYc5xrJhJisNV8m-hlMeSKZWnn2RE/edit#">Reportáž týmu Hlavně se nezranit</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/1-rcqM4JRMd4rY61SINwew-1CU0bI6UrYO6DBksSiw-U/edit">Reportáž týmu Kvapící Fronkáros</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/10KPNWJlggItvHmjyaVoVbypqghHAgwNAaKO2-4PgB04/edit">Reportáž týmu Nadbytečný pleonasmus</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href={`https://2020.navic.sifrovacky.cz/reports/rep.pdf`}>Reportáž týmu JÁDRO</a></li>
						<li><a target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/G4JwUCjASKs6eNeM7">Orgovská fotogalerie</a></li>
					</ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
