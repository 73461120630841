import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { Link } from 'gatsby'

class CiphersList extends React.Component {
	render() {
		const comments = this.props.pageContext.comments.data
		const ciphers = this.props.pageContext.ciphers.data.ciphers.map( c => {
			const commentForCipher = comments.filter( comment => { return comment.thread === c.id } )
			if ( commentForCipher[0] ) {
				c.commentString = commentForCipher[0] ? ` (celkem ${commentForCipher[0].count}, poslední ${commentForCipher[0].timestamp})` : null
			}
			return c
		} )

		console.log(ciphers)

		const listHt = ciphers
			.filter( c => c.type === 'ht' && c.id !== 'a1' )
			.map( c => <li key={c.id}><Link to={ `/sifry/${c.id}`}>{c.id}. {c.name}</Link>{c.commentString}</li> )

		const listNavic = ciphers
			.filter( c => c.type === 'navic' || c.id === 'a1' )
			.map( c => <li key={c.id}><Link to={ `/sifry/${c.id}`}>{c.id}. navíc</Link>{c.commentString}</li> )

		return (
			<Layout>
			<SEO
			title="Šifry"
			description="Šifry ze hry Navíc, která se konala v Praze 13. června 2020."
			/>
				<div id="main">
					<section id="one">
						<div className="inner">
							<header className="major">
								<h2>Šifry</h2>
							</header>
							<h3>Hlavní trasa</h3>
							<ul>
								{listHt}
							</ul>

							<h3>Úkoly navíc</h3>
							<ul>
								{listNavic}
							</ul>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}
export default CiphersList
