import React from 'react'
import FormMessages from './FormMessages';

class LoginForm extends React.Component {
    render() {
        return (
            <form onSubmit={this.props.loginCallback}>
                <label htmlFor="name">Název týmu*</label>
                <input type="text" maxLength="60" name="name" id="name" required />

                <label htmlFor="password">Heslo*</label>
                <input type="password" name="password" id="password" required />

                <FormMessages errors={this.props.errors} successMessage={this.props.successMessage} />

                <p className="center"><input type="submit" value="Přihlásit se" /></p>
            </form>
        )
    }
}

export default LoginForm
