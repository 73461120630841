import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import cr from '../assets/images/cz-outline-plain.svg'
import dn_praha from '../assets/images/dn-praha.svg'
import dn_brno from '../assets/images/dn-brno.svg'
import dn_nmnm from '../assets/images/dn-nmnm.svg'
import dn_zruc from '../assets/images/dn-zruc.svg'

const Pravidla = (props) => (
    <Layout>
        <SEO
        title="Den Navíc"
		description="Den Navíc, který se koná 29. února 2020."
		pathname="/den-navic"
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Den Navíc</h2>
                    </header>

					<p>Den Navíc je za námi. Hledači mohli 29. února ve čtyřech schránkách rozmístěných mezi Prahou a Brnem najít drobné dárky, konkrétně <strong>šifrovací blok a odznáček</strong> (ten na každém místě s unikátním motivem).</p>
					<ul>
						<li><a target="_blank" href="https://photos.google.com/share/AF1QipNYQGejb4DyQieiQE6XIImOgb-1ecj7jn-Y5Cm2pfHWLjlHF3f7tLaxu8yqfSJO1A?key=bDJqMlJ0UHFGMUJzTk5VMzRCQl9qWXc2SmtmQzVn" rel="noopener noreferrer">Fotky ze Dne Navíc</a></li>
					</ul>
					<p>V předvečer Dne Navíc jsme také na Facebooku <a href="https://i.paste.pics/3166d6fdd289b913ee2e025516d3bd3a.png?trs=be49f506ea50f37f057115c4142fbf396861330bcaccb91256aacc5fb6acdb24" target="_blank" rel="noopener noreferrer">uveřejnili komentář</a>, který už dopředu <strong>zašifrovaně prozrazoval</strong> polohu všech stanovišť. Stačilo z každého odstavce vzít poslední slova každé věty a tato interpretovat jako adresu v české variantě adresovacího systému <a href="https://what3words.com/" target="_blank" rel="noopener noreferrer">what3words</a>.</p>
					<div class="dn-sifra">
						<p>Víme, co vám na šifrovačce pomůže zazářit. Vhodná šifrovací pomůcka. A šifra v tomhle textu je jenom možná.</p>
						<p>Krabice bývají většinou hranaté. To by vás ovšem nemělo vyrušit. V nich je to, co vám chceme rozdat.</p>
						<p>To zajímavé je náplň. Překonat budete muset nejedno stoupání. Dárky tam nebudou navždy.</p>
						<p>No tak honem. Radíme vám už tak hodně. Dál už ale nechceme ustoupit.</p>
					</div>
					<p>Někteří hráči šifru rozluštili a potkali se na stanovištích už v 6.45, tedy v čase oficiálního začátku Dne Navíc. Speciální zmínku zaslouží tým <strong>Lamzilla</strong>, který brněnskou skrýš otevřel už v noci v <strong>1.41</strong> před oficiálním začátkem Dne Navíc. Ráno jsme umístění stanovišť prozradili nešifrovaně.</p>

					<div id="den-navic-area">
						<img alt="Obrys republiky" src={cr} className="navic-cr" />
						<div className="dn-cover dn-praha">
							<a href="#zazářit.pomůcka.možná"><img src={dn_praha} alt="Odznak Navíc v oblasti Prahy" /></a>
						</div>
						<div className="dn-cover dn-zruc">
							<a href="#hranaté.vyrušit.rozdat"><img src={dn_zruc} alt="Odznak Navíc v oblasti Zruče nad Sázavou" /></a>
						</div>
						<div className="dn-cover dn-nmnm">
							<a href="#náplň.stoupání.navždy"><img src={dn_nmnm} alt="Odznak Navíc v oblasti Nového Města na Moravě" /></a>
						</div>
						<div className="dn-cover dn-brno">
							<a href="#honem.hodně.ustoupit"><img src={dn_brno} alt="Odznak Navíc v oblasti Brna" /></a>
						</div>
					</div>

					<h3 class="dn-section" id="zazářit.pomůcka.možná">Praha: Schodiště Navíc (<a target="_blank" href="https://what3words.com/honem.hodně.ustoupit" rel="noopener noreferrer">mapa</a>)</h3>
					<img class="dn-button-inline" src={dn_praha} alt="Odznak Navíc v oblasti Prahy" />
					<p>V Praze byla schránka schována ve výklenku zaniklého schodiště v Krčském lese. Po dlouhou dobu byl původ a účel schodiště obestřen záhadou, až konečně na začátku letošního roku portál <em>Krčák žije</em> tajemství rozluštil. Jde o opravdu napínavé čtení! Přečtěte si <a href="https://www.krcakzije.cz/lokalita/zahadne-schodiste-v-krcskem-lese.html" target="_blank" rel="noopener noreferrer">první</a> i <a href="https://www.krcakzije.cz/lokalita/tajemne-schodiste-v-krcskem-lese-2-dil.html" target="_blank" rel="noopener noreferrer">druhou</a> část článku.</p>
					<p>První zde byly týmy <strong>Zvířátka</strong> a <strong>Kanonický divisor</strong> v 6.47. Vrcholovou knihou můžete listovat <a href="https://photos.google.com/share/AF1QipPFziV6ptXMOoqG8o5lJpwAK-1lU86y5u2Hx1AHYyMh-9iQD-wuEByT6TYYtehRZQ/photo/AF1QipPVJMuSSAjyct3zOj9z77YirX-Egma8y_TOzPAl?key=U2g3ZjNZSWk5TzcyTUsyckVTaWxRNzNSVjlmMWZn" target="_blank" rel="noopener noreferrer">zde</a>.</p>

					<h3 class="dn-section" id="hranaté.vyrušit.rozdat">Zruč nad Sázavou: Zatopené mosty Navíc (<a target="_blank" href="https://what3words.com/hranaté.vyrušit.rozdat" rel="noopener noreferrer">mapa</a>)</h3>
					<img class="dn-button-inline" src={dn_zruc} alt="Odznak Navíc v oblasti Zruče nad Sázavou" />
					<p>V oblasti Zruče nad Sázavou jsme schránku umístili na břeh vodní nádrže Švihov nedaleko vesnice Borovsko. Je odtam působivý výhled na jeden z mostů nedostavěné protektorátní dálnice. Stavba mostů byla započata roku 1939, ale roku 1950 byla výstavba dálnic komunistickým režimem zastavena. V letech 1965 - 75 probíhala výstavba vodní nádrže Švihov a oba mosty byly zatopeny téměř po úroveň mostovky. Projekt dálnice D1 dokončené roku 1977 byl kvůli vodní nádrži přepracován a tak vede o několik stovek metrů jižněji. Mosty jsou nepřístupné, nacházejí se v ochranném pásmu vodní nádrže.</p>
					<p>První zde byl tým <strong>Dutá Vlákna</strong> v 9.45. Vrcholovou knihou můžete listovat <a href="https://photos.google.com/share/AF1QipPFziV6ptXMOoqG8o5lJpwAK-1lU86y5u2Hx1AHYyMh-9iQD-wuEByT6TYYtehRZQ/photo/AF1QipMEZOcRZ95mBnekttF6mefaRHQWWVN2ACH1OJ1m?key=U2g3ZjNZSWk5TzcyTUsyckVTaWxRNzNSVjlmMWZn" target="_blank" rel="noopener noreferrer">zde</a>.</p>

					<h3 class="dn-section" id="náplň.stoupání.navždy">NMnM: Dráty Navíc (<a target="_blank" href="https://what3words.com/náplň.stoupání.navždy" rel="noopener noreferrer">mapa</a>)</h3>
					<img class="dn-button-inline" src={dn_nmnm} alt="Odznak Navíc v oblasti Nového Města nad Moravou" />
					<p>Schránka u Nového Města na Moravě se nacházela u námrazové stanice poblíž vsi Studnice. Tři vysoké stožáry u Studnice - nejvýše položené obce na Vysočině - zde stojí od roku 1980. Na první pohled se mohou zdát jako nedostavěné sloupy vysokého napětí, ale ve skutečnosti se jedná o stanici pro výzkum námrazy. V současnosti se vleče spor mezi výzkumným ústavem a správou CHKO o odstranění staveb.</p>
					<p>První zde byl tým <strong>komurci</strong> v 7.31. Vrcholovou knihou můžete listovat <a href="https://photos.google.com/share/AF1QipPFziV6ptXMOoqG8o5lJpwAK-1lU86y5u2Hx1AHYyMh-9iQD-wuEByT6TYYtehRZQ/photo/AF1QipM995_aR99-UM6ukwn5Mm_Ad0TA7QswXk5ix6uj?key=U2g3ZjNZSWk5TzcyTUsyckVTaWxRNzNSVjlmMWZn" target="_blank" rel="noopener noreferrer">zde</a>.</p>

					<h3 class="dn-section" id="honem.hodně.ustoupit">Brno: Most Navíc (<a target="_blank" href="https://what3words.com/honem.hodně.ustoupit" rel="noopener noreferrer">mapa</a>)</h3>
					<img class="dn-button-inline" src={dn_brno} alt="Odznak Navíc v oblasti Nového Města nad Moravou" />
					<p>Stanoviště u Brna se nacházelo v Ostopovicích u mostu nedokončené tzv. Hitlerovy dálnice. Po obou stranách zde stojí pouze čela bočních stěn mostu s volným prostorem uvnitř dálničního tělesa. Napříč měla procházet místní komunikace. Poblíž objektu je i informační tabule místní naučné stezky. Most je dnes součástí obecního přírodního parku, který zahrnuje zdejší drobné lesíky, rozlehlou zahrádkářskou kolonii a chatovou osadu.</p>
					<p>První zde byl tým <strong>Lamzilla</strong> v 1.41 (už v noci před Dnem Navíc). Vrcholovou knihou můžete listovat <a href="https://photos.google.com/share/AF1QipPFziV6ptXMOoqG8o5lJpwAK-1lU86y5u2Hx1AHYyMh-9iQD-wuEByT6TYYtehRZQ/photo/AF1QipOVhHu-WrYm4-lXe8sMVj1UqBrNZVUKKe0FW9P_?key=U2g3ZjNZSWk5TzcyTUsyckVTaWxRNzNSVjlmMWZn" target="_blank" rel="noopener noreferrer">zde</a>.</p>

					<hr />

					<h3>Původní text</h3>

					<p>Letos nám kalendář podává pomocnou ruku a přináší taktéž něco navíc. Celý jeden den: <strong>sobotu 29. února</strong>. Rozhodli jsme se proto připravit vám ke Dni Navíc možnost získat malou pozornost. Řekněme dárek, artefakt, suvenýr.</p>
					<p>Na <strong>čtyřech místech někde mezi Prahou a Brnem</strong> čeká na předem neznámý počet hledačů v připravených schránkách <em>něco navíc</em>. Něco užitečného. A zajímavého. Pěkného! Mimo možnost získání nevšedního artefaktu vás vyšleme na místa, kam se běžně spíš nevydáte. Vyražte na výlet s rodinou, partnerem nebo jen sami se sebou a užijte si přestupnou sobotu venku.</p>
					<p>Stanoviště budou otevřena celý Den, ale mezi námi, za tmy to není ono. <a href="https://www.tmou.cz/" target="_blank" rel="noopener noreferrer">Tmou</a> choďte v listopadu. ;) <strong>Přesnou polohu zveřejníme</strong> na této stránce pomocí <a href="http://what3words.com/" target="_blank" rel="noopener noreferrer">polohovacího systému w3w</a> za úsvitu Dne Navíc.</p>
					<p>Stay tuned.</p>
					<p>P. S. Dne Navíc se <strong>můžete účastnit nezávisle</strong> na účasti na Navíc navíc.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Pravidla
