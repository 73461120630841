import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

class Vysledky extends React.Component {
	render() {
		return (
			<Layout>
			<SEO
			title="Výsledky"
			description="Výsledky šifrovačky Navíc, která se konala v Praze 13. června 2020."
			/>
				<div id="main">
					<section id="one">
						<div className="inner">
							<header className="major">
								<h2>Výsledky</h2>
							</header>
							<p>Do oficiální výsledkové listiny jsou zařazeny týmy, které odevzdaly cílové heslo. Na neoficiální výsledky a další zajímavá data se můžete podívat <a href="https://statek.seslost.cz/navic-2020/poradi">na Statku</a> (děkujeme, Tome).</p>

							<h3>Pořadí v kategorii <em>Na čas</em></h3>
							<p>(Pořadí pro kategorii <em>Víc bodů</em> najdete níž na stránce)</p>

<table className="nobreak">
	<thead>
		<tr>
			<th>#</th>
			<th>Název týmu</th>
			<th>Cíl</th>
			<th>Body</th>
		</tr>
	</thead>
<tbody>
<tr><td>1</td><td>Ztraceni na severu</td><td>14:39:22</td><td>10</td></tr>
<tr><td>2</td><td>O stupeň navíc</td><td>14:45:00</td><td>17</td></tr>
<tr><td>3</td><td>Tomovo</td><td>18:08:31</td><td>0</td></tr>
<tr><td>4</td><td>poTrati</td><td>18:23:43</td><td>34</td></tr>
<tr><td>5</td><td>Degen a spol</td><td>18:41:22</td><td>9</td></tr>
<tr><td>6</td><td>Tlupa</td><td>18:58:47</td><td>11</td></tr>
<tr><td>7</td><td>Kvapící Fronkáros</td><td>19:21:07</td><td>7</td></tr>
<tr><td>8</td><td>Happy Matfyz Friends</td><td>19:21:48</td><td>19</td></tr>
<tr><td>9</td><td>Společenští poustevníci</td><td>19:42:58</td><td>4</td></tr>
<tr><td>10</td><td>Tranquilo, señor!</td><td>19:52:02</td><td>34</td></tr>
<tr><td>11</td><td>Divize nulou</td><td>20:00:27</td><td>45</td></tr>
<tr><td>12</td><td>Fast and Curious</td><td>20:08:11</td><td>5</td></tr>
<tr><td>13</td><td>Dieter's Ghetto Intelectuals</td><td>20:15:43</td><td>3</td></tr>
<tr><td>14</td><td>Pomalá Mořská Víla</td><td>20:16:39</td><td>25</td></tr>
<tr><td>15</td><td>Želvonohý</td><td>20:18:44</td><td>16</td></tr>
<tr><td>16</td><td>JTHTN</td><td>20:26:47</td><td>25</td></tr>
<tr><td>17</td><td>Made in Czechoslovakia</td><td>20:28:49</td><td>12</td></tr>
<tr><td>18</td><td>Povyrostlo nám seno</td><td>20:30:09</td><td>52</td></tr>
<tr><td>19</td><td>Aplikovaní Hrošíci</td><td>20:30:54</td><td>45</td></tr>
<tr><td>20</td><td>Večernice</td><td>20:37:17</td><td>22</td></tr>
<tr><td>21</td><td>O.H.L.A.</td><td>20:43:20</td><td>13</td></tr>
<tr><td>22</td><td>Absinthenti</td><td>20:44:55</td><td>17</td></tr>
<tr><td>23</td><td>Nadbytečný pleonasmus</td><td>20:45:43</td><td>100</td></tr>
<tr><td>24</td><td>Štamgasti od Kance a kopí</td><td>20:47:04</td><td>14</td></tr>
<tr><td>25</td><td>Zebra řez</td><td>20:48:02</td><td>72</td></tr>
<tr><td>26</td><td>Borci v uvozovkách</td><td>20:48:09</td><td>21</td></tr>
<tr><td>27</td><td>Tupě ignorované tramvaje</td><td>20:48:11</td><td>26</td></tr>
<tr><td>28</td><td>Team Avatar</td><td>20:48:28</td><td>83</td></tr>
<tr><td>29</td><td>Spící Volové</td><td>20:49:29</td><td>57</td></tr>
<tr><td>30</td><td>Na výletě</td><td>20:50:57</td><td>45</td></tr>
<tr><td>31</td><td>Svinský Bob</td><td>20:51:02</td><td>30</td></tr>
<tr><td>32</td><td>Hakuna Matata</td><td>20:52:00</td><td>43</td></tr>
<tr><td>33</td><td>Bazinga</td><td>20:52:38</td><td>91</td></tr>
<tr><td>34</td><td>Fčeličky</td><td>20:54:01</td><td>4</td></tr>
<tr><td>35</td><td>JÁDRO</td><td>20:54:12</td><td>53</td></tr>
<tr><td>36</td><td>Kanonický divisor</td><td>20:54:28</td><td>44</td></tr>
<tr><td>37</td><td>Kočky</td><td>20:54:32</td><td>29</td></tr>
<tr><td>38</td><td>Žvířátka</td><td>20:55:31</td><td>39</td></tr>
<tr><td>39</td><td>Hlavně se nezranit</td><td>20:55:59</td><td>57</td></tr>
<tr><td>40</td><td>Squeak!</td><td>20:56:41</td><td>18</td></tr>
<tr><td>41</td><td>něco rozumnějšího</td><td>20:57:22</td><td>31</td></tr>
<tr><td>42</td><td>ˇ&lt;</td><td>20:58:13</td><td>61</td></tr>
</tbody>
</table>

<br />

							<h3>Pořadí v kategorii <em>Víc bodů</em></h3>

							<table className="nobreak"><thead><tr><th>#</th>
<th>Název týmu</th>
<th>Čas</th>
<th>Body</th></tr></thead><tbody>
<tr><td>1</td><td>Nadbytečný pleonasmus</td><td>20:45:43</td><td>100</td></tr>
<tr><td>2</td><td>Bazinga</td><td>20:52:38</td><td>91</td></tr>
<tr><td>3</td><td>Team Avatar</td><td>20:48:28</td><td>83</td></tr>
<tr><td>4</td><td>Zebra řez</td><td>20:48:02</td><td>72</td></tr>
<tr><td>5</td><td>ˇ&lt;</td><td>20:58:13</td><td>61</td></tr>
<tr><td>6</td><td>Spící Volové</td><td>20:49:29</td><td>57</td></tr>
<tr><td>7</td><td>Hlavně se nezranit</td><td>20:55:59</td><td>57</td></tr>
<tr><td>8</td><td>JÁDRO</td><td>20:54:12</td><td>53</td></tr>
<tr><td>9</td><td>Povyrostlo nám seno</td><td>20:30:09</td><td>52</td></tr>
<tr><td>10</td><td>Divize nulou</td><td>20:00:27</td><td>45</td></tr>
<tr><td>11</td><td>Aplikovaní Hrošíci</td><td>20:30:54</td><td>45</td></tr>
<tr><td>12</td><td>Na výletě</td><td>20:50:57</td><td>45</td></tr>
<tr><td>13</td><td>Kanonický divisor</td><td>20:54:28</td><td>44</td></tr>
<tr><td>14</td><td>Hakuna Matata</td><td>20:52:00</td><td>43</td></tr>
<tr><td>15</td><td>Žvířátka</td><td>20:55:31</td><td>39</td></tr>
<tr><td>16</td><td>poTrati</td><td>18:23:43</td><td>34</td></tr>
<tr><td>17</td><td>Tranquilo, señor!</td><td>19:52:02</td><td>34</td></tr>
<tr><td>18</td><td>něco rozumnějšího</td><td>20:57:22</td><td>31</td></tr>
<tr><td>19</td><td>Svinský Bob</td><td>20:51:02</td><td>30</td></tr>
<tr><td>20</td><td>Kočky</td><td>20:54:32</td><td>29</td></tr>
<tr><td>21</td><td>Tupě ignorované tramvaje</td><td>20:48:11</td><td>26</td></tr>
<tr><td>22</td><td>Pomalá Mořská Víla</td><td>20:16:39</td><td>25</td></tr>
<tr><td>23</td><td>JTHTN</td><td>20:26:47</td><td>25</td></tr>
<tr><td>24</td><td>Večernice</td><td>20:37:17</td><td>22</td></tr>
<tr><td>25</td><td>Borci v uvozovkách</td><td>20:48:09</td><td>21</td></tr>
<tr><td>26</td><td>Happy Matfyz Friends</td><td>19:21:48</td><td>19</td></tr>
<tr><td>27</td><td>Squeak!</td><td>20:56:41</td><td>18</td></tr>
<tr><td>28</td><td>O stupeň navíc</td><td>14:45:00</td><td>17</td></tr>
<tr><td>29</td><td>Absinthenti</td><td>20:44:55</td><td>17</td></tr>
<tr><td>30</td><td>Želvonohý</td><td>20:18:44</td><td>16</td></tr>
<tr><td>31</td><td>Štamgasti od Kance a kopí</td><td>20:47:04</td><td>14</td></tr>
<tr><td>32</td><td>O.H.L.A.</td><td>20:43:20</td><td>13</td></tr>
<tr><td>33</td><td>Made in Czechoslovakia</td><td>20:28:49</td><td>12</td></tr>
<tr><td>34</td><td>Tlupa</td><td>18:58:47</td><td>11</td></tr>
<tr><td>35</td><td>Ztraceni na severu</td><td>14:39:22</td><td>10</td></tr>
<tr><td>36</td><td>Degen a spol</td><td>18:41:22</td><td>9</td></tr>
<tr><td>37</td><td>Kvapící Fronkáros</td><td>19:21:07</td><td>7</td></tr>
<tr><td>38</td><td>Fast and Curious</td><td>20:08:11</td><td>5</td></tr>
<tr><td>39</td><td>Společenští poustevníci</td><td>19:42:58</td><td>4</td></tr>
<tr><td>40</td><td>Fčeličky</td><td>20:54:01</td><td>4</td></tr>
<tr><td>41</td><td>Dieter's Ghetto Intelectuals</td><td>20:15:43</td><td>3</td></tr>
<tr><td>42</td><td>Tomovo</td><td>18:08:31</td><td>0</td></tr>
</tbody></table>


						</div>
					</section>
				</div>
			</Layout>
		)
	}
}
export default Vysledky
