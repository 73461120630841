import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="Poděkování"
        description="Poděkování po hře k šifrovací hře Navíc, která se konala v Praze 13. června 2020."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Poděkování</h2>
                    </header>
                    <p>Letošní šifrovačka Navíc navíc by nemohla proběhnout bez vydatné podpory našich
                    kamarádů a blízkých, jakožto i bez materiálního zajištění a dobrých náhod. Těm
                    poděkovat nemůžeme, ale vám, kteří jste nám s něčím pomohli, patří velké díky!</p>
                    <p>Jsou to &hellip;</p>
                    <p>&hellip; testeři šifer Mirek a Lucka Klimošovi, Michal Blaha, Honza Chabr,
                    Jindřich Cinka, Jiří Kozel, Filip Valášek, Láďa Skákal, Pavel Houdek, Verča Dočkalová,
                    Míra Brumovský, Jana Pačísková a Vendula Bednářová, kteří s námi a nebo na dálku
                    luštili starší verze šifer a pomohli nám z nich udělat to, co jste viděli na hře,</p>
                    <p>&hellip; pomocníci na trase Paču, Vendy, Denča a Radek, kterých letos sice kvůli
                    epidemii bylo méně než bývá zvykem, stejně jako úkolů, které mohli obsluhovat, což
                    ale nezmenšuje jejich nasazení,</p>
                    <p>&hellip; dodavatelé materiálu:</p>
                    <ul>
                        <li>už tradiční tiskářská firma <a href="http://www.kopirovani-havranek.cz/">
                        Kopírování Havránek</a>, </li>
                        <li>netradiční tiskárna <a href="https://tygrtisk.cz/">Tygrtisk.cz</a>,</li>
                        <li>letošní specialista na zakázkovou výrobu krabic
                        <a href="https://www.box48.cz/">box48.cz</a>,</li>
                        <li>tiskárna reklamních předmětů <a href="http://www.potisk.com/">Potisk.com</a>,
                        výrobci bloků <a href="https://www.eprinting.cz/">Eprinting.cz</a> a placek
                        <a href="http://www.mojeplacky.cz/">MojePlacky.cz</a>,</li>
                    </ul>
                    <p>&hellip; Matěj Klusáček aka pan Fenek, který pro nás složil a nahrál šifru d (Slam),</p>
                    <p>&hellip; a v neposlední řadě děkujeme kolektivu 4. přístavu vodních skautů Jana Nerudy
                    za poskytnutí pronájmu jedinečných cílových prostor.</p>
                    <p>Hra by ovšem nemohla proběhnout bez vás, hráčů, kteří jste do nás i letos vložili svou
                    důvěru, že vám připravíme kvalitní šifrovací zábavu, a přišli za námi nejen na start, ale
                    i do cíle, kde jste nám pomohli vytvořit příjemné prostředí u šifer a ohně (díky, hudebníci!)
                    stejně jako loni, navzdory nepřízni počasí.</p>
                    <p><i>Aleš</i>, <i>Dero</i>, <i>Michal</i> a <i>Šárka</i></p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
