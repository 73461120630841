import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/Seo'
import { Link } from 'gatsby'

const Thanks = (props) => (
    <Layout>
        <SEO
        title="O hře"
        description="O hře Navíc, která se konala v Praze 13. června 2020."
        />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>O hře</h2>
                    </header>
					<ul>
						<li><Link onClick={props.onToggleMenu} to="/pravidla">Pravidla</Link></li>
						<li><Link onClick={props.onToggleMenu} to="/faq">Časté otázky</Link></li>
						<li><Link onClick={props.onToggleMenu} to="/tymy">Registrované týmy</Link></li>
						<li><Link onClick={props.onToggleMenu} to="/vas-tym">Váš tým</Link></li>
					</ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Thanks
