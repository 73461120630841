import React from 'react'
import api from '../helpers/api'
import DiscussionComment from './DiscussionComment';

class Discussion extends React.Component {
    constructor() {
		super();
		this.state = {
			commentMeta: {
				name: '',
				team: '',
				antispam: '',
			},
			comments: [],
			sending: false,
		}

		this.newMessage = this.newMessage.bind(this);
		this.refreshComments = this.refreshComments.bind(this);
	}

	componentDidMount() {
		const discussionDetails = {
			name: window.localStorage.getItem('navic_discussionDetails_name'),
			team: window.localStorage.getItem('navic_discussionDetails_team'),
			antispam: window.localStorage.getItem('navic_discussionDetails_antispam'),
		}

		if ( discussionDetails.name ) {
			this.setState({
				commentMeta: discussionDetails
			})
		}

		this.refreshComments()
	}

	newMessage(e) {
		e.preventDefault()
		this.setState({
			sending: true
		})
		const messageFormData = new FormData(e.target)

		const messageMeta = {
			name: messageFormData.get('name'),
			team: messageFormData.get('team'),
			antispam: messageFormData.get('antispam'),
		}
		const messageText = messageFormData.get('message')
		if ( ! messageMeta.name || ! messageText || ! messageMeta.antispam.match( /c[ií]van/i ) ) {
			alert( 'Vyplňte správně všechna povinná pole' )
			this.setState({
				sending: false
			})
		} else {
			this.setState({
				commentMeta: messageMeta
			})
			window.localStorage.setItem('navic_discussionDetails_name', messageMeta.name)
			window.localStorage.setItem('navic_discussionDetails_team', messageMeta.team)
			window.localStorage.setItem('navic_discussionDetails_antispam', messageMeta.antispam)

			api.post( '/new-comment', messageFormData, (response) => {
				if (response.data.status === 'ok') {
					this.refreshComments()
				} else {
					alert( 'Nepodařilo se odeslat komentář :(' )
				}
				this.setState({
					sending: false
				})
			})
		}
	}

	refreshComments() {
		document.getElementById('message_field').value = ''

		api.get( '/comments/' + this.props.thread, (response) => {
			if ( response.data.status === 'ok') {
				this.setState({
					comments: response.data.data
				})
			}
		})
	}

	render() {
		return (
			<>
				{ this.state.comments ? <a href="#new-message-form">Přidat komentář</a> : <></> }

				{this.state.comments.map(c => <DiscussionComment comment={c} />)}

				<form id="new-message-form" onSubmit={this.newMessage}>
					<label htmlFor="name">Jméno*</label>
					<input type="text" maxLength="60" name="name" id="name" defaultValue={this.state.commentMeta.name} required />

					<label htmlFor="team">Název týmu (volitelně)</label>
					<input type="text" name="team" id="team" defaultValue={this.state.commentMeta.team} />

					<label htmlFor="antispam">Název hry pozpátku (obrana proti spamu)*</label>
					<input type="text" name="antispam" id="antispam" required defaultValue={this.state.commentMeta.antispam} />

					<input type="hidden" name="thread" value={this.props.thread} />

					<label htmlFor="message">Komentář*</label>
					<textarea id="message_field" name="message" rows="8" required></textarea>
					<p>Povoleny <a href="http://www.edgering.org/markdown/" target="_blank" rel="noopener noreferrer">Markdown</a> značky, tj. například **tučné**, *kurzíva*, hvězdička na začátku řádku je položka seznamu atp.</p>

					<p className="center"><input disabled={this.state.sending ? 'disabled' : ''} type="submit" value="Nová zpráva" /></p>
				</form>
			</>
		)
	}
}
export default Discussion
