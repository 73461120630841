import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/Seo'

class Faq extends React.Component {
	componentDidMount() {
		const paragraphs = document.querySelectorAll('p[id]');
		let linkToParagraph;

		paragraphs.forEach( paragraph => {
			linkToParagraph = document.createElement( 'a' );
			linkToParagraph.setAttribute( 'href', '#' + paragraph.getAttribute( 'id' ) );
			linkToParagraph.setAttribute( 'class', 'anchor-inline' );
			linkToParagraph.innerText = '#';

			paragraph.prepend( linkToParagraph );
		} );
	}

	render() {
		return (
		<Layout>
			<SEO
			title="Časté otázky"
			description="Časté otázky k šifrovací hře Navíc, která se koná v Praze 13. června 2020."
			/>

			<div id="main">
				<section id="one">
					<div className="inner">
						<header className="major">
							<h2>Časté otázky</h2>
							<p>Změny proti loňsku jsme označili <em className="new-2020">žlutě</em>.</p>
						</header>
						<br />
						<p id="jakoloni" className="strong">Vidíme, že se <a href="https://2020.navic.sifrovacky.cz/2019/">oproti loňsku</a> pravidla ani časté otázky vlastně nezměnily. Bude hra stejná jako loni?</p>
						<p><em className="new-2020">Bude podobná. Herní formát se osvědčil a my nechceme sahat do věcí, které fungují. Ale tohle je Navíc, něco zajímavého <em>navíc</em> ve hře určitě najdete.</em></p>

						<p id="opatreni" className="strong">Jak se hra změní s ohledem na pandemii?</p>
						<p><em className="new-2020">Hra bude! Zajistíme, aby neobsahovala hromadný start, mezitýmové interakce ani aktivity, které by vedly k interakcím s organizátory na trase. (Pořád můžete potkat orga, který hlídá šifry, ale budete od sebe bezpečně daleko.)</em></p>
						<p><em className="new-2020">Stejně jako loni bude cíl na zahradě <a href="https://mapy.cz/s/3p4b6">skautské klubovny Pitterova</a>. Budete si tam moct pokecat s orgy, natočit pivo, limo, upéct špekáček nebo prohlédnout řešení šifer. Vnitřní prostory klubovny však budou – s výjimkou WC – nepřístupné. Účast v cíli není povinná, hru bude možné dokončit i bez nutnosti do cíle vstupovat.</em></p>

						<p id="prokazdeho" className="strong">Co si máme představit pod pojmem „šifrovačka pro každého“?</p>
						<p>Znamená to, že tvoříme <a href="https://sifrovacky.cz/o-sifrovackach/co-jsou-sifrovacky/">šifrovačku</a> tak, aby si dobře zahráli hráči všech výkonnostních úrovní. A to i týmy složené jen ze začátečníků, nebo naopak družstva plná zkušených matadorů.</p>
						<p>Základním prvkem hry bude hlavní trasa. Na té můžete čekat jednodušší a středně těžké šifry, které s občasnou dopomocí nápovědy zvládnou vyluštit i začátečníci.</p>
						<p>Mimo hlavní trasu se ve hře budou vyskytovat tzv. <em>úkoly navíc</em>. Ty žádný předem daný charakter nemají. Můžete očekávat jak menší šifřičky vhodné k doplnění bodů pro „nákup“ nápověd, tak obtížné šifry pro frajery mířící za vítězstvím v kategorii <em>Víc bodů</em>, různé chytáky, úchylárny i nešifrovací úkoly.</p>

						<p id="kategorie" className="strong">Můžete trochu rozvést ty dvě kategorie hodnocení?</p>
						<p>Jasně. Po skončení hry z týmů, které dokončí hru, sestavíme dvě výsledkové listiny. Hra tedy bude mít i dva vítěze.</p>
						<p>V kategorii <em>Na čas</em> se hodnotí výhradně čas dokončení hry úspěšným projitím hlavní trasy. Nepřihlíží se k žádným nápovědám (ani řešením), které na trase použijete. Může být výhodné použít strategickou nápovědu pro urychlení luštění, pokud na ni máte body.</p>
						<p>V kategorii <em>Víc bodů</em> se týmy, které dokončí hru, hodnotí podle počtu bodů, které mají na kontě v okamžiku odevzdání cílového hesla. Body sbíráte plněním <em>úkolů navíc</em>, tj. mimo hlavní trasu hry. Bacha, použitím nápověd a řešení body zase ztrácíte!</p>
						<p>Pár upřesňujících poznámek:</p>
						<ul>
							<li>Odevzdáním cílového hesla pro vás hra končí. Není pak už možné pokračovat řešením <em>úkolů navíc</em> a sbírat tak body do kategorie <em>Víc bodů</em>.</li>
							<li>Při remíze v kategorii <em>Víc bodů</em> rozhoduje čas dokončení hry (dříve je lépe).</li>
						</ul>

						<p className="strong" id="jsmefrajeri">Takže… pokud jsme fakt dobří, můžeme zvítězit v obou kategoriích?</p>
						<p>Teoreticky jo. Ale to fakt nedáte. :)</p>

						<p id="nezasifrovane-ukoly" className="strong">Co znamená, že informace o <em>úkolech navíc</em> jsou poskytovány v nezašifrované podobě?</p>
						<p>Znamená to, že zadání <em>úkolů navíc</em> budete dostávat na stanovištích hlavní trasy explicitně. Dostanete postupně jednotlivá zadání a budete si vědomi počtu bodů, které splněním jednotlivých <em>úkolů navíc</em> můžete získat. Neznamená to však, že budete vždy bez přemýšlení vědět, jakým způsobem lze úkol splnit.</p>

						<p className="strong" id="cossebou">Co si máme vzít s sebou?</p>
						<p>Nepůjde to bez tužky a chytrého telefonu s internetem.</p>
						<p>Pro usnadnění luštění dále doporučujeme čtverečkovaný papír, izolepu, nůžky, pravítko, kružítko, úhloměr, zvýrazňovače, fólie.</p>
						<p>Pro navigaci se hodí Mapy.cz v mobilu (<a href="https://play.google.com/store/apps/details?id=cz.seznam.mapy">Android</a>, <a href="https://itunes.apple.com/cz/app/mapy-cz/id411411020">iOS</a>), které při přípravě hry používáme i my. Protože navigace, googlení a focení selfíček žere baterku, může se vám hodit i powerbanka.</p>
						<p>Nezapomeňte i na osobní komfort a vezměte si pohodlné boty na městské a mírně terénní chození. Dál něco na sezení (třeba karimatku) a na snězení (třeba svačinu). Hodit se může i deštník proti slunci (a nedejbože dešti), vějíř proti vedru <em className="new-2020">a dost možná i rouška proti lidem. ;)</em></p>

						<p className="strong" id="cotrasa">Jak bude vypadat trasa, zvládneme to ujít?</p>
						<p>Zvládnete. Délka hlavní trasy se pohybuje mezi 10–15 kilometry, je dobře sjízdná kočárkem a vede městem a dobře prostupnou městskou zelení. Můžete si z postupu hlavní trasou hry udělat třeba hezký rodinný výlet, stanoviště jsou na to uzpůsobena. Nezapomeňte, že děti do 12 let věku se nepočítají do počtu členů týmu.</p>
						<p><em className="new-2020">Letos hlavní trasa obsahuje dva delší přesuny. Dají se ujít pěšky, ale výrazně rychlejší a pohodlnější je absolvovat je MHD.</em></p>
						<p><em>Úkoly navíc</em> vás výjimečně mohou poslat na navigačně náročnější nebo hůře schůdná místa, tak s tím počítejte, pokud se chcete věnovat i této části hry.</p>

						<p id="paty" className="strong">Koho si do týmu můžeme vzít jako pátého hráče navíc?</p>
						<p>Místo zamýšlíme hlavně pro někoho, komu chcete ukázat váš koníček. Ale vezměte třeba i kamaráda, který sice byl na Sendviči před deseti roky, ale máte chuť si s ním zase zaluštit. Nebo někoho, kdo šel šifrovací trail a přijde mu to fajn zkusit dělat celý den. Obsazení nejsilnějšího možného hráče, který se do této rozmazané škatulky vejde, nepovažujeme za smysl její existence.</p>

						<p id="pes" className="strong">Chceme na hru vzít psa, šlo by to?</p>
						<p>Na hru samotnou psa vzít není problém, ale do cíle mají psi vstup zakázán. Pokud přijdete na chvíli posedět, můžete ho uvázat před brankou, ale pokud budete chtít přespávat, doporučujeme psa odvézt domů nebo ho tam rovnou nechat.</p>

						<p className="strong" id="deleni">Můžeme se na hře rozdělit a luštit paralelně víc úloh?</p>
						<p>Úloh můžete souběžně luštit, kolik jenom zvládnete. Tým však smíte fyzicky rozdělit maximálně na dvě části. Přečtěte si <Link to="/pravidla#co-ano-co-ne">povolené a zakázané postupy</Link> v pravidlech.</p>

						<p className="strong" id="online">Může část našeho týmu luštit z domova?</p>
						<p><em className="new-2020">Ano, za dodržení pravidla o dělení týmu maximálně na dvě části, přičemž hráči luštící z domova se souhrnně považují pouze za jednu část týmu. Znamená to tedy, že část týmu v terénu se už nesmí dělit. Upozorňujeme, že toto může být nevýhodné zejména pro týmy, které soutěží v kategorii „Víc bodů“.</em></p>

						<p className="strong" id="prespani">Jak bude vypadat večerní program a přespání v cíli?</p>
						<p><em className="new-2020">Přespání letos nabídnout nemůžeme, jinak to bude podobné jako loni. Cíl bude na zahradě <a href="https://mapy.cz/s/3p4b6">skautské klubovny Pitterova</a>. Budete si tam moct pokecat s orgy, natočit pivo, limo, upéct špekáček nebo prohlédnout řešení šifer. Přinesete-li hudební nástroje, můžeme si večer u táboráku zazpívat. Pouze vnitřní prostory klubovny letos budou – s výjimkou WC – nepřístupné.</em></p>

						<p className="strong" id="parkovani">Dá se v okolí startu nebo cíle zaparkovat?</p>
						<p><em className="new-2020">Ano. Na Praze 10 není parkování zónami regulováno a na Praze 3 o víkendu můžete parkovat v modrých i fialových zónách zdarma.</em></p>
						<p><em className="new-2020">V okolí startu lze zaparkovat přímo v ulici Pod Plískavou a přilehlých ulicích, případně na parkovišti v ulici Vladycké. Kromě toho nedaleké obchodní centrum <a href="https://vivo-shopping.com/cs/hostivar/kontakt" target="_blank" rel="noopener noreferrer">Vivo Hostivař nabízí</a> svým zákazníkům neomezené využití krytého parkovacího stání.</em></p>
						<p>Poblíž cíle doporučujeme parkování pod obytnými budovami, tj. ve východní části ulice Pitterova, v ulici Ke Kapslovně, případně na západním konci ulice Malešické.</p>

						<p className="strong" id="posledni-info">Pošlete ještě před začátkem hry nějaké „Poslední informace“?</p>
						<p>Pošleme. Čekat je můžete nejpozději ve středu před hrou.</p>

						<p id="webinfo" className="strong">Kam budeme zaznamenávat postup hrou?</p>
						<p>Dosažení stanoviště nebo získání hesla z <em>úkolu navíc</em> nahlásíte na webové stránce (tzv. webinfo), jejíž adresu vám pošleme spolu s „Posledními informacemi“.</p>

						<p className="strong" id="statek">A jak se <a href="https://statek.seslost.cz/o-statku" target="_blank" rel="noopener noreferrer">Statek</a> popere se dvěma kategoriemi hodnocení?</p>
						<p>Nepopere. Před hrou sice uvidíte <a href="https://statek.seslost.cz/navic-2020/nasazeni" target="_blank" rel="noopener noreferrer">nasazení</a>, ale po hře se žádné body rozdělovat (ani brát) nebudou. Tak se nebojte experimentovat se složením týmu.</p>

						<p className="strong" id="orgove">Kdo jste, orgové?</p>
						<p>Aleš, Dero, Mike a Šárka. Organizovali jsme například několik <a href="https://matrix.velkyvuz.cz/archiv">Matrixů</a>. Dneska už na přípravu akce typu Matrix nemáme čas, ale tvořit se nám stále chce. A tak pořádáme menší denní hru, která se nám určitě nevymkne z kloubů a nepřeroste přes hlavu. :)</p>

						<p className="strong" id="otazky-navic">Máme ještě otázky navíc…</p>
						<p>Ano? Pošlete nám mail na <a href="mailto:navic@sifrovacky.cz">navic@sifrovacky.cz</a>.</p>
					</div>
				</section>
			</div>
		</Layout>
		)
	}
}

export default Faq
