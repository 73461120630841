import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Úvod</Link></li>
				<li><Link onClick={props.onToggleMenu} to="/vysledky">Výsledky</Link></li>
				<li><Link onClick={props.onToggleMenu} to="/sifry">Šifry a řešení</Link></li>
				<li><Link onClick={props.onToggleMenu} to="/reportaze">reportáže a fotky</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/diskuse">Diskuse</Link></li>
				<li><Link onClick={props.onToggleMenu} to="/o-hre">Informace o hře</Link></li>
            </ul>
			{ /*
			<ul className="actions vertical">
                <li><Link to="/registrace" className="button fit">Registrace</Link></li>
			</ul>
			*/ }
        </div>
        <span className="anchor close" onClick={props.onToggleMenu} onKeyPress={props.onToggleMenu} role="button" tabIndex="0">Close</span>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
