import React from 'react'
import Layout from '../components/layout'
import BannerAfter from '../components/BannerAfter'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <BannerAfter />

                <div id="main">
                </div>
            </Layout>
        )
    }
}

export default HomeIndex
